<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Dealer</label>
            <b-select
              v-model="filter.dealer"
              :options="filterDealer"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            ref="table"
            hover
            responsive
            class="mt-2"
            :per-page="tableSettings.perPage"
            :current-page="tableSettings.currentPage"
            :items="myProvider"
            :fields="tableSettings.fields"
            :sort-by.sync="tableSettings.sortBy"
            :sort-desc.sync="tableSettings.sortDesc"
            :sort-direction="tableSettings.sortDirection"
            :filter="tableSettings.filter"
            :filter-included-fields="tableSettings.filterOn"
            show-empty
          >
            <template #cell(index)="data">
              {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index +
                1)
              }}
            </template>

            <template #cell(active)="data">
              {{ data.item.active === 1 ? "Yes" : "No" }}
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onShowProductDetails(row.item)"
                >
                  Show Products
                </b-button>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableSettings.perPage"
                  :options="tableSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableSettings.currentPage"
                :total-rows="tableSettings.totalRows"
                :per-page="tableSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-product-show"
      scrollable
      size="xl"
      no-close-on-backdrop
      title="Products List"
      class="mb-5"
      @ok="onConfirmSubmit"
    >

      <div>
        <b-table
          striped
          hover
          :items="productlist_dealer"
          :fields="tableShowProductDetails.fields"
        >
          <template #cell(ending_quantity)="data">
            <ValidationObserver ref="form">
              <form
                role="form"
                novalidate
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="ending_quantity"
                  vid="ending_quantity"
                  rules="numeric"
                >
                  <div class="form-group">
                    <b-input
                      id="ending_quantity"
                      v-model="data.item.ending_quantity"
                      class="ending_quantity"
                      type="number"
                      placeholder="Enter Ending Quantity "
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>
              </form>
            </ValidationObserver>
          </template>
          <template #cell(total_quantity)="data">
            {{ data.item.quantity }}
          </template>

        </b-table>

      </div>
      <b-row>
        <b-col cols="12">
          <div class="form-group">

            <b-checkbox
              id="isDone"
              v-model="stock_details.is_done"
              name="isDone"
              value="1"
              unchecked-value="0"
            >
              Done in Ending Inventory ?
            </b-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="form-group">
            <label for="comment">Enter Comment: </label>
            <textarea
              id="comment"
              v-model="stock_details.comment"
              name="comment"
              rows="5"
              placeholder="Enter Comment"
              class="form-control"
            />
          </div>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="disableSubmit"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedUserService, ListService, StockService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserInventoryEnding',
  middleware: ['auth', 'user'],
  mixins: [formatter],

  data () {
    return {
      isBusy: true,
      editMode: false,
      stock_details: {
        user_id: '',
        dealer_id: '',
        inventory_type: '',
        comment: '',
        is_done: 0
      },
      dealers: [],
      filter: {
        dealer: 'All'
      },
      product: {
        product_category_id: '',
        product_id: '',
        quantity: ''
      },
      product_categories: [],
      products: [],
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'area_code', sortable: true },
          { key: 'area_name', sortable: true },
          { key: 'dealer_code', sortable: true },
          { key: 'dealer_name', sortable: true },
          { key: 'address', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' }
        ]
      },
      tableProductDetails: {
        fields: [
          {
            key: 'product_category_id',
            sortable: true
          },
          {
            key: 'product_category_name',
            sortable: true
          },
          {
            key: 'product_id',
            sortable: true
          },
          {
            key: 'product_name',
            sortable: true
          },
          {
            key: 'quantity',
            sortable: true
          },
          'action'
        ]
      },
      tableShowProductDetails: {
        fields: [
          {
            key: 'product_category_name',
            sortable: true
          },
          {
            key: 'product_name',
            sortable: true
          },
          {
            key: 'total_quantity',
            sortable: true
          },
          {
            key: 'ending_quantity',
            sortable: true
          }

        ]

      },
      items: [],
      productlist_dealer: [],
      user: this.$store.getters['auth/user']
    }
  },

  computed: {
    filterDealer () {
      return [{ value: 'All', text: 'All' }].concat(this.dealers)
    },
    disableSubmit () {
      return this.productlist_dealer.filter(el => el.ending_quantity > 0).length > 0 ? false : 'disabled'
    }
  },
  watch: {
    'product.product_category_id.value' (value) {
      this.getProduct(value)
    },
    'filter.dealer' () {
      this.$refs.table.refresh()
    }

  },

  metaInfo () {

  },

  mounted () {
    core.index()
    this.getProductCategory()
    this.getDealer()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SharedUserService.getDealerByDcp(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_dealer=${this.filter.dealer}&user_id=${this.user.id}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getDealer () {
      await ListService.getDealers().then(({ data }) => {
        this.dealers = data.map(({ id, dealer_name }) => {
          return { value: id, text: dealer_name }
        })
      })
    },

    async getProductCategory () {
      this.product_categories = []
      await ListService.getProductCategories().then(({ data }) => {
        data.forEach(item => {
          this.product_categories.push({
            value: item.id,
            text: item.product_category_name,
            label: item.product_category_name
          })
        })
      })
    },

    async getProduct (productCategories) {
      this.products = []
      await ListService.getProducts(`product_category_id=${productCategories}`).then(({ data }) => {
        data.forEach(item => {
          this.products.push({
            value: item.id,
            text: item.product_name,
            label: item.product_name
          })
        })
      })

      if (this.products.length > 0) {
        this.product.product_id = this.product_name[0].value
      }
    },

    async onShowProductDetails (item) {
      this.stock_details.dealer_id = item.dealer_id
      this.stock_details.inventory_type = 'minus'
      this.$bvModal.show('modal-product-show')

      this.productlist_dealer = []
      await StockService.getProductsListByDealer(`dealer_id=${item.dealer_id}&status=ending`).then(({ data }) => {
        this.productlist_dealer = data.data.map(el => {
          return { ...el, ending_quantity: el.quantity }
        })
      })
    },

    async onSubmitPostForm () {
      const obj = {
        dealer_id: this.stock_details.dealer_id,
        inventory_type: this.stock_details.inventory_type,
        status: 'ending',
        product_details: this.productlist_dealer.filter(el => el.ending_quantity > 0),
        comment: this.stock_details.comment,
        is_done: this.stock_details.is_done
      }

      this.isBusy = true
      StockService.post(obj).then(response => {
        this.isBusy = false
        this.$swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.$bvModal.hide('modal-product-show')
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
          }
        }
        this.isBusy = false
      })
    },

    async onConfirmSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          if (!this.editMode) {
            const message = !this.stock_details.is_done ? 'partial ' : ''
            this.$swal.fire({
              title: `Are you sure want to create this ${message} ending inventory ?`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Submit',
              cancelButtonColor: '#FF2929',
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.onSubmitPostForm()
              },

              allowOutsideClick: () => !this.$swal.isLoading()

            })
          }
        } else {
          this.$swal.fire({
            icon: 'warning',
            title: 'Validation Error',
            text: 'Oppss! There were problem with your inputs.',
            confirmButtonColor: '#FF2929',
            confirmButtonText: 'Dismiss'
          })
        }
      })
    }

  }
}
</script>
